<template>
  <div class="user-contacts">
    <contact
      v-for="(contact, i) of contacts"
      :key="contact.id"
      :contact="contact"
      :editable="editable"
      :class="{ 'mt-4': i !== 0 }"
      @deleted="$emit('contact-deleted', contact)"
    />

    <contact
      v-if="isNewContactFormVisible"
      class="mt-4"
      is-new
      @created="handleContactCreated"
      @cancel="isNewContactFormVisible = false"
    />

    <div v-if="!contacts.length && !isNewContactFormVisible" class="ml-3">Не добавлены</div>

    <div v-if="editable" class="mt-5">
      <v-btn color="secondary" :disabled="isNewContactFormVisible" small @click="isNewContactFormVisible = true">
        Добавить сотрудника
      </v-btn>
    </div>
  </div>
</template>

<script>
  import Contact from './Contact'

  export default {
    name: 'UserContacts',
    components: { Contact },

    props: {
      contacts: {
        type: Array,
        default: () => []
      },
      editable: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        isNewContactFormVisible: false
      }
    },

    methods: {
      handleContactCreated(contact) {
        this.$emit('contact-created', contact)
        this.isNewContactFormVisible = false
      }
    }
  }
</script>

<!--<style lang="scss" scoped></style>-->
