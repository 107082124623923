<template>
  <!-- eslint-disable vue/no-static-inline-styles -->
  <page-container class="user-profile d-flex justify-center" :title="$t('pages.titles.userProfile')" force-header>
    <template #title-content>
      <v-spacer />

      <template v-if="isOwnProfile">
        <v-btn
          v-if="userData.has_rts_binding"
          :disabled="isPending || pendingTasks.unbindUserFromRts"
          color="secondary"
          @click="unbindUserFromRts()"
        >
          Отвязать профиль от учетной записи РТС
        </v-btn>

        <v-btn
          v-else
          class="text--white"
          :href="rtsLoginUrl"
          rel="noreferrer noopener"
          :disabled="isPending"
          depressed
          color="rtsPrimary"
          @click.prevent="handleBindUserToRts()"
        >
          Привязать профиль к учетной записи РТС-тендер
        </v-btn>
      </template>

      <div v-else class="align-self-center" :class="{ 'rtsPrimary--text': userData.has_rts_binding }">
        {{ userData.has_rts_binding ? 'Привязан к РТС-тендер' : 'Не привязан к РТС-тендер' }}
      </div>
    </template>

    <div class="block-subtitle mt-8">Организация</div>

    <v-sheet class="mt-3 px-4 pt-8 pb-1" :elevation="0">
      <v-text-field v-model="userData.login" type="email" disabled label="Почта" dense filled />

      <template v-if="isOwnProfile">
        <v-select
          v-model="userData.account_type"
          style="grid-column: 2 / 3"
          disabled
          label="Право доступа"
          :items="accountTypeOptions"
          dense
          filled
        />

        <v-form v-if="isFormToChangePasswordVisible" ref="passwordForm" class="d-contents">
          <password-input
            id="old-password"
            v-model="changePasswordFormModel.old_password"
            class="required"
            style="grid-column: 4 / 5"
            autocomplete="current-password"
            label="Старый пароль"
            :rules="formRules.requiredProperty"
            dense
            filled
            @change="changePasswordFormModel.new_password && $refs.passwordForm.validate()"
          />

          <password-input
            id="new-password"
            v-model="changePasswordFormModel.new_password"
            class="required"
            style="grid-column: 5 / 6"
            autocomplete="new-password"
            label="Новый пароль"
            :rules="formRules.requiredProperty"
            dense
            filled
          />

          <div class="d-flex justify-space-evenly mt-2" style="grid-column: 6 / 7">
            <v-btn
              :disabled="isPending || pendingTasks.changePassword"
              :loading="pendingTasks.changePassword"
              icon
              @click="cancelChangePassword()"
            >
              <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
            </v-btn>

            <v-btn
              :disabled="isPending || pendingTasks.changePassword"
              :loading="pendingTasks.changePassword"
              icon
              @click="changePassword()"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </div>
        </v-form>

        <v-btn
          v-else
          class="mt-2"
          style="grid-column: 6 / 7"
          :disabled="isPending"
          small
          text
          @click="isFormToChangePasswordVisible = true"
        >
          Изменить пароль
        </v-btn>
      </template>

      <template v-else-if="isAdmin">
        <base-form
          v-model="accountTypeFormModel"
          style="grid-column: 5 / 6"
          :disabled="isPending"
          :configuration="accountTypeFormConfiguration"
        />

        <v-btn
          class="mt-3"
          style="grid-column: 6 / 7"
          :disabled="isPending || accountTypeFormModel.account_type === userData.account_type"
          :loading="pendingTasks.saveUserAccountType"
          small
          depressed
          color="primary"
          @click="saveUserAccountType()"
        >
          Применить
        </v-btn>
      </template>
    </v-sheet>

    <v-form ref="form" :disabled="isPending || !isOwnProfile">
      <v-sheet class="mt-3 px-4 pt-8 pb-1" :elevation="0">
        <v-text-field id="ogrn" v-model="formModel.ogrn" label="ОГРН" :rules="formRules.ogrn" dense filled />

        <v-text-field
          id="inn"
          v-model="formModel.inn"
          class="required"
          label="ИНН"
          :rules="formRules.inn"
          dense
          filled
        />

        <v-text-field
          id="full_name"
          v-model="formModel.full_name"
          class="required"
          style="grid-column: 4 / 7"
          label="Полное наименование"
          :rules="formRules.requiredProperty"
          dense
          filled
        />

        <v-text-field
          id="director"
          v-model="formModel.director"
          style="grid-column: 1 / 4"
          label="Генеральный директор"
          dense
          filled
        />

        <v-text-field
          id="short_name"
          v-model="formModel.short_name"
          style="grid-column: 4 / 7"
          label="Сокращенное наименование"
          dense
          filled
        />

        <v-text-field
          id="phone"
          v-model="formModel.phone"
          class="required"
          autocomplete="tel"
          :rules="formRules.requiredProperty"
          label="Телефон"
          dense
          filled
        />

        <v-text-field
          id="legal_address"
          v-model="formModel.legal_address"
          style="grid-column: 4 / 7"
          label="Юридический адрес"
          dense
          filled
        />

        <v-text-field
          id="address"
          v-model="formModel.address"
          style="grid-column: 1 / 4"
          label="Местонахождение организации"
          dense
          filled
        />

        <v-text-field
          id="mail_address"
          v-model="formModel.mail_address"
          style="grid-column: 4 / 7"
          label="Почтовый адрес"
          dense
          filled
        />
      </v-sheet>
    </v-form>

    <div v-if="isOwnProfile" class="d-flex mt-5">
      <v-spacer />

      <v-btn color="secondary" :disabled="isPending || hasNotChanges" small depressed @click="resetForm()">
        Отменить
      </v-btn>

      <v-btn
        class="ml-3"
        color="primary"
        :disabled="isPending || hasNotChanges"
        :loading="isPending"
        small
        depressed
        @click="saveUser()"
      >
        Применить
      </v-btn>
    </div>

    <div class="block-subtitle mt-8">Контактные лица</div>

    <user-contacts
      class="mt-3"
      :editable="isOwnProfile"
      :contacts="userContacts"
      @contact-created="handleContactCreated"
      @contact-deleted="handleContactDeleted"
    />
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { removeValueFromArray } from 'geoportal/src/utils/func'
  import { showMessage } from '@/utils/messages'
  import { formatTextInputValue, innRule, ogrnRule, requiredPropertyRule } from '@/utils/forms'
  import UserContacts from './UserContacts/UserContacts'
  import { AccountType } from '@/enums/aquarius'
  import { createFormConfiguration, formFieldPresets } from '@/components/BaseForm/formHelpers'

  const formRules = {
    requiredProperty: [requiredPropertyRule],
    inn: [requiredPropertyRule, innRule],
    ogrn: [ogrnRule]
  }

  const accountTypeFormConfiguration = createFormConfiguration([
    [{ id: 'account_type', label: 'Право доступа', bind: { items: [] } }, formFieldPresets.select]
  ])

  export default {
    name: 'UserProfileView',
    components: { UserContacts },
    mixins: [PendingTasksManagerMixin],

    props: {
      userId: {
        type: String,
        default: undefined
      }
    },

    data() {
      return {
        formRules,

        changePasswordFormModel: {
          old_password: null,
          new_password: null
        },
        isFormToChangePasswordVisible: false,

        accountTypeFormModel: {},
        formModel: {
          ogrn: null,
          inn: null,
          full_name: null,
          director: null,
          short_name: null,
          phone: null,
          legal_address: null,
          address: null,
          mail_address: null
        },
        userData: {},
        userContacts: []
      }
    },

    computed: {
      rtsLoginUrl() {
        return this.$auth.getRtsLoginUrl()
      },

      isOwnProfile() {
        return !this.userId || this.userId === this.$auth.user.id
      },

      isAdmin() {
        return this.$auth.user.account_type === AccountType.ADMIN
      },

      accountTypeOptions() {
        return Object.values(AccountType).map((accountType) => ({
          text: this.$t(`users.accountType.${accountType}`),
          value: accountType
        }))
      },

      accountTypeFormConfiguration() {
        const configuration = _.cloneDeep(accountTypeFormConfiguration)
        configuration[0].bind.items = this.accountTypeOptions
        return configuration
      },

      hasNotChanges() {
        return _.isEqual(
          _.mapValues(this.formModel, formatTextInputValue),
          _.pick(this.userData, Object.keys(this.formModel))
        )
      }
    },

    watch: {
      userId: {
        handler: 'init',
        immediate: true
      }
    },

    methods: {
      setUserData(user) {
        this.accountTypeFormModel = _.pick(user, ['account_type'])
        this.formModel = {
          ...this.formModel,
          ..._.pick(user, Object.keys(this.formModel))
        }
      },

      resetForm() {
        this.setUserData(this.userData)
      },

      @handlePendingTask()
      async fetchUser() {
        this.userData = this.userId
          ? await this.$api.backend.users.get_user({ id: this.userId })
          : await this.$api.backend.users.get_current_user()
        this.setUserData(this.userData)
      },

      async init() {
        const requests = [
          this.userId
            ? this.$api.backend.users.get_user({ id: this.userId })
            : this.$api.backend.users.get_current_user(),
          this.$api.backend.user_contacts.get_contacts_by_user()
        ]
        const [userData, userContacts] = await Promise.all(requests)

        this.userData = userData
        this.userContacts = userContacts

        this.setUserData(this.userData)
      },

      cancelChangePassword() {
        this.isFormToChangePasswordVisible = false
        this.changePasswordFormModel.old_password = null
        this.changePasswordFormModel.new_password = null
      },

      @handlePendingTask('changePassword')
      async changePassword() {
        if (!this.$refs.passwordForm.validate()) {
          return
        }

        await this.$api.backend.users.change_own_password(this.changePasswordFormModel)
      },

      @handlePendingTask()
      async saveUserGeneric(requestData) {
        this.userData = this.userId
          ? await this.$api.backend.users.update_user({ id: this.userId, ...requestData })
          : await this.$api.backend.users.update_current_user(requestData)
        this.setUserData(this.userData)

        if (!this.userId || this.userId === this.$auth.user.id) {
          this.$auth.setUser(_.cloneDeep(this.userData))
        }

        showMessage('Пользователь успешно обновлен')
      },

      @handlePendingTask('saveUser')
      async saveUser() {
        if (!this.$refs.form.validate()) {
          return
        }

        const requestData = _.pickBy(
          _.mapValues(this.formModel, formatTextInputValue),
          (value, key) => !_.isEqual(value, this.userData[key])
        )

        await this.saveUserGeneric(requestData)
      },

      @handlePendingTask('saveUserAccountType')
      async saveUserAccountType() {
        await this.saveUserGeneric(this.accountTypeFormModel)
      },

      handleContactCreated(contact) {
        this.userContacts.push(contact)
      },

      handleContactDeleted(contact) {
        removeValueFromArray(this.userContacts, contact)
      },

      handleBindUserToRts() {
        this.$auth.redirectToRtsLoginForm(this.$route.path)
      },

      @handlePendingTask('unbindUserFromRts')
      async unbindUserFromRts() {
        await this.$api.backend.rts.unbind_user()
        await this.$auth.updateUser()
        await this.fetchUser()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-profile {
    .v-sheet {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 12px;
    }
  }
</style>
