<template>
  <!-- eslint-disable vue/no-static-inline-styles -->
  <v-sheet class="contact px-4 pt-8 pb-1">
    <v-form ref="form" :disabled="!isNew || pendingTasks.createContact">
      <v-text-field
        v-model="contactData.full_name"
        style="grid-column: 1 / 4"
        autocomplete="name"
        label="ФИО сотрудника"
        dense
        filled
      />

      <v-text-field
        v-model="contactData.phone"
        style="grid-column: 4 / 5"
        autocomplete="tel"
        label="Телефон"
        dense
        filled
      />

      <v-text-field
        v-model="contactData.email"
        style="grid-column: 5 / 6"
        autocomplete="email"
        label="Почта"
        :rules="[emailRule]"
        dense
        filled
      />

      <div class="d-flex justify-space-evenly align-center mb-details">
        <template v-if="isNew">
          <v-btn :disabled="pendingTasks.createContact" icon @click="$emit('cancel')">
            <v-icon>{{ $vuetify.icons.values.close }}</v-icon>
          </v-btn>

          <v-btn
            :disabled="pendingTasks.createContact || hasNotChanges"
            :loading="pendingTasks.createContact"
            icon
            @click="createContact()"
          >
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>

        <v-btn
          v-else-if="editable"
          class="delete-btn"
          :disabled="pendingTasks.deleteContact"
          :loading="pendingTasks.deleteContact"
          icon
          @click="deleteContact()"
        >
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
      </div>
    </v-form>
  </v-sheet>
</template>

<script>
  import _ from 'lodash'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'
  import { emailRule, formatTextInputValue } from '@/utils/forms'

  export default {
    name: 'Contact',
    mixins: [PendingTasksManagerMixin],

    props: {
      contact: {
        type: Object,
        default: () => {}
      },
      editable: {
        type: Boolean,
        default: false
      },
      isNew: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        emailRule,

        formModel: {
          full_name: null,
          phone: null,
          email: null
        }
      }
    },

    computed: {
      contactData() {
        return this.isNew ? this.formModel : this.contact
      },

      hasNotChanges() {
        return Object.values(this.formModel).every((value) => _.isNil(formatTextInputValue(value)))
      }
    },

    methods: {
      @handlePendingTask('createContact')
      async createContact() {
        if (!this.$refs.form.validate()) {
          return
        }

        const contact = await this.$api.backend.user_contacts.create_contact(this.formModel)
        this.$emit('created', contact)
      },

      @handlePendingTask('deleteContact')
      async deleteContact() {
        // TODO: ask if user is sure
        await this.$api.backend.user_contacts.delete_contact({ id: this.contact.id })
        this.$emit('deleted')
      }
    }
  }
</script>

<style lang="scss" scoped>
  .contact {
    .v-form {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      column-gap: 12px;
    }

    &:not(:hover) .delete-btn {
      opacity: 0;
    }
  }
</style>
